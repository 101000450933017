<template lang="pug">
    div.step-3
        div.inner-block
            div.select-skill-div
                p.text-averta-light-32 {{$t('title.new.skill')}}
                p.subtitle-averta-regular-18.grey-text {{$t("onboarding.step3.title")}}
                div.body
                    .left
                        div.search-skills
                            .top
                                p.text-averta-semibold-22 {{$t('add.new.skill')}}
                                p.subtitle-averta-regular-18.description {{$t('description.new.skill')}}
                                skill-search-bar(:placeholder="$t('search.skill')" :withLevel="true" :withAdd="true" :message="$t('inexistskill')" @addTag="addToSkills")
                            .bottom
                                div.category-thematic-block(v-if="!$store.getters.isMobile && withSkillCategory")
                                    div.category(v-for="category in categoryThematicList")
                                        div.category-block(@click="setSelectedCategory(category)" :class="{'rotated': selectedCategory && selectedCategory.category.id == category.category.id}")
                                            p.subtitle-averta-semibold-18 {{category.category.label}}
                                            img.icon.icon-24(src="/img/svg/dropdown.svg")
                                        div.category-skill-list(v-if="selectedCategory && selectedCategory.category.id == category.category.id")
                                            div.skill-block
                                                div.skill(v-for="skill in category.thematic" @mouseover="selectedSkillId = skill.id" v-if="selectedSkillsIds.indexOf(skill.id) == -1 && userSkillIds.indexOf(skill.id) == -1")
                                                    p.subtitle-averta-regular-18.label(:class="{'text-wrap': selectedSkillId == skill.id}") {{skill.label}}
                                                    div.choose-level(v-if="selectedSkillId == skill.id")
                                                        div.stars
                                                            img.icon.icon-24(src="/img/svg/star-on-icon.svg" v-for="n in 5" :class="{inactive: n > currentLevel}" @mouseover="currentLevel = n" @click="addToSkills({skill: skill, level: currentLevel})")
                                                        p.subtitle-averta-regular-18 {{ $t($store.getters['common/thematic/getLabel'](currentLevel)) }}
                            div.selected-skills(v-if="query == '' && $store.getters.isMobile")
                                div.selected-skill(v-for="(skill, index) in selectedSkills" :key="skill.id")
                                    one-line-skill(:text="skill.label" :skillLevel="skill.level" @remove="selectedSkills.splice(index, 1)")
                    .right
                        div.summary-skills(v-if="!$store.getters.isMobile")
                            p.text-averta-semibold-22 {{$t('add.skilllist')}}
                            div.added-skill-container
                                div.skills-added-list(:data-placeholder="$t('search.addskill2')")
                                    div.skill-row(v-for="(skill, index) in selectedSkills" :key="skill.id + skillClickCounter" :level="skill.level")
                                        one-line-skill(:text="skill.label" :skill="skill" :skillLevel="skill.level" @remove="selectedSkills.splice(index, 1)" @setCurrentLevel="updateLevel" @removeSkill="selectedSkills.splice(index, 1)")
                        div.button-block(v-if="!$store.getters.isMobile")
                            button.button.btn-light(@click="$router.push('/step1')") {{$t('back')}}
                            button.button.btn-primary-square(@click="confirm()") {{$t('validate')}}
                p.text-averta-regular-20.contact-us Dear all, please contact us at knowledge@scor.com for any technical issue.

</template>

<script>
export default {
    name: 'Step3',
    data() {
        return {
            selectedSkills: [],
            query: '',
            currentLevel: 1,
            skillClickCounter: 0,
            selectedSkillId: false,
            selectedCategory: false,
            withSkillCategory: process.env.VUE_APP_WITH_SKILL_CATEGORY === 'true',
        };
    },
    components: {
        SkillSearchBar: () => import('skillbase-component/src/components/common/input/SkillSearchBar.vue'),
        OneLineSkill: () => import('skillbase-component/src/components/common/tags/OneLineSkill.vue'),
    },

    computed: {
        skillList() {
            return this.$store.getters['common/thematic/listAll'];
        },

        username() {
            return this.$store.getters.user.username;
        },

        categoryThematicList() {
            let list = this.$store.getters['common/category/categoryThematicList'];
            let toReturn = [];
            if (list) {
                Object.keys(list).forEach((key) => {
                    if (list[key].category.label != 'other') {
                        toReturn.push(list[key]);
                    }
                });

                if (list[0]) {
                    toReturn.push(list[0]);
                }
            }
            return toReturn;
        },

        selectedSkillsIds() {
            let toReturn = [];
            for (let i = 0; i < this.selectedSkills.length; i++) {
                toReturn.push(this.selectedSkills[i].id);
            }
            return toReturn;
        },

        searchedSkills() {
            let toReturn = [];

            if (this.query != '') {
                for (let i = 0; i < this.skillList.length; i++) {
                    let skill = this.skillList[i];
                    if (skill.label.toLowerCase().includes(this.query.toLowerCase()) && this.userSkillIds.indexOf(skill.id) === -1) {
                        skill.hightligtedLabel = this.matchLabel(skill.label);
                        toReturn.push(skill);
                    }
                }
            }

            return toReturn;
        },

        userSkillIds() {
            let userSkills = this.$store.getters['user-profile/getUser'](this.username).skills;
            let toReturn = [];
            if (userSkills) {
                for (let level = 1; level <= 5; level++) {
                    let skills = userSkills[level];
                    if (skills) {
                        skills.forEach((skill) => {
                            toReturn.push(skill.id);
                        });
                    }
                }
            }
            return toReturn;
        },
    },
    methods: {
        setSelectedCategory(category) {
            this.selectedCategory = this.selectedCategory === false || this.selectedCategory.category.id != category.category.id ? category : false;
            this.$forceUpdate();
        },

        confirm() {
            let allPromise = [];
            this.selectedSkills.forEach((el) => {
                allPromise.push(
                    this.$store.dispatch('user-profile/addSkill', {
                        id: el.id,
                        level: el.level,
                        username: this.username,
                    })
                );
            });

            Promise.all(allPromise).then(response => {
                this.$router.push("/thanks")
            })
        },
        setSelectedId(id) {
            if (this.selectedSkillId == id) {
                this.currentLevel = 1;
                this.selectedSkillId = false;
            } else {
                this.selectedSkillId = id;
            }
            this.$forceUpdate();
        },
        matchLabel(current) {
            let regExp = new RegExp(this.query, 'ig');
            let found = current.search(regExp) !== -1;
            let string = regExp.exec(current);
            return !found ? current : current.replace(regExp, '<b class="title-7">' + string[0] + '</b>');
        },
        updateLevel(value) {
            this.skillClickCounter++;
            value.skill.level = value.level;
            this.$forceUpdate();
        },
        addToSkills(value) {
            value.skill.level = value.level;
            this.selectedSkills.push(value.skill);
        },
    },
    watch: {
        username: {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                if (newValue && newValue != oldValue) {
                    this.$store.dispatch('user-profile/fetchUserSkills', this.username);
                }
            },
        },
    },
    mounted() {
        this.$store.dispatch('common/thematic/fetchAllThematics');
        this.$store.dispatch('common/category/fetchCategoryThematicList');
    },
    // computed: {
    //     userSkillList() {
    //         let skillList = this.$store.getters["user-profile/getUser"](this.username);
    //         return  skillList&& skillList.skills !== undefined ? skillList.skills : [];
    //     },
    // },
    // watch: {
    //     userSkillList: {
    //         immediate: true,
    //         deep: true,
    //         handler(newValue, oldValue) {
    //             if (newValue && newValue != oldValue) {
    //                 this.selectedSkills = [];
    //                 this.selectedSkillIds = [];
    //                 Object.keys(newValue).forEach(el => {
    //                     newValue[el].forEach(skill => {
    //                         skill.level = el
    //                         this.selectedSkillIds.push(skill.id)
    //                         this.userSkillIds.push(skill.id)
    //                         this.selectedSkills.push(skill)
    //                     })
    //                 })
    //             }
    //         }
    //     },
};
</script>

<style scoped lang="scss">
.step-3 {
    background: url('/img/welcomepage.png');
    width: 100vw !important;
    margin-left: 0 !important;
    height: 100vh !important;
    overflow: hidden !important;
    padding-left: 50px !important;
    padding-right: 50px !important;
    background-size: cover;
    padding-top: 0px !important;

    .inner-block {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        .select-skill-div {
            width: 1019px;
            background: white;
            height: 614px;
            padding: 35px;
            position: relative;
            border-radius: 4px;
            .grey-text{
                color: var(--grey-1-color);
            }
            .body {
                display: flex;
                margin-top: 16px;
                flex-direction: row;

                .search-skills {
                    margin-right: 52px;
                    .description {
                        color: var(--grey-1-color);
                        margin-top: 6px;
                        margin-bottom: 10px;
                    }
                }

                .top {
                    width: 441px;
                }

                .category-thematic-block {
                    height: 425px;
                    margin: 15px 0 0 0;
                    padding-right: 14px;
                    padding-left: 2px;
                    overflow-y: scroll;
                    .category {
                        margin-bottom: 14px;
                        box-shadow: 0px 1px 6px var(--shadow-color);
                        position: relative;
                        border-radius: 4px;
                        width: 441px;

                        .category-block {
                            height: 35px;
                            margin-left: 11px;
                            cursor: pointer;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            img {
                                margin-right: 11px;
                            }
                        }

                        .rotated {
                            img {
                                transform: rotate(180deg);
                            }

                            border-radius: 0px 0px 4px 4px;
                        }

                        .category-skill-list {
                            width: 441px;
                            z-index: 9999;

                            .skill-block {
                                background-color: var(--white-color);
                                box-shadow: 0 2px 2px var(--shadow-color);
                                overflow-y: overlay;
                                max-height: 170px;
                                border-radius: 0px 0px 4px 4px;
                            }

                            .skill {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                cursor: pointer;
                                height: 35px;
                                overflow: hidden;
                                padding: 4px;
                                p {
                                    margin-left: 11px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2;
                                    -webkit-box-orient: vertical;
                                    word-break: break-all;
                                }

                                .text-wrap {
                                    max-width: 320px;
                                }

                                &:hover {
                                    background: var(--primary-light-hover-color);
                                    .label {
                                        color: var(--primary-color);
                                    }
                                }
                            }

                            .choose-level {
                                text-align: center;

                                p {
                                    margin-top: 0;
                                    color: var(--grey-1-color);
                                }

                                .stars {
                                    img {
                                        margin-right: 6px;
                                        filter: var(--svg-filter-primary-color);
                                        cursor: pointer;

                                        &.inactive {
                                            filter: var(--svg-filter-primary-light-color);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .summary-skills {
                    .added-skill-container {
                        padding: 18px;
                        border: 2px solid var(--grey-3-color);
                        border-radius: 3px;
                        height: 412px;
                        width: 464px;
                        margin-top: 10px;
                    }

                    .skills-added-list {
                        display: flex;
                        flex-wrap: wrap;
                        overflow-y: scroll;
                        max-height: 450px;

                        &:empty:before {
                            content: attr(data-placeholder);
                            color: var(--grey-2-color);
                            font-family: 'Averta regular', Arial, Helvetica, sans-serif;
                            font-size: 13px;
                            font-weight: lighter;
                            overflow-y: hidden;
                        }

                        .skill-row {
                            display: flex;
                            align-items: center;
                            margin-bottom: 7px;
                            margin-right: 13px;

                            p {
                                margin-right: 10px;
                            }

                            .star-container {
                                display: flex;

                                .star {
                                    margin-right: 6px;
                                    filter: var(--svg-filter-primary-color);
                                    width: 18px;
                                    height: 18px;
                                    cursor: pointer;

                                    &.inactive {
                                        filter: var(--svg-filter-primary-light-color);
                                    }
                                }
                            }

                            .remove {
                                width: 20px;
                                height: 20px;
                                cursor: pointer;
                                filter: var(--svg-filter-text-color);
                            }
                        }
                    }
                }
                .button-block {
                    margin-top: 35px;
                    text-align: right;
                    display: flex;
                    justify-content: flex-end;
                    gap: 18px;
                }
            }
            .contact-us {
                position: absolute;
                color: var(--white-color);
                bottom: -25px;
                left: 0px;
            }
        }
    }
}
</style>
